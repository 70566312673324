@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
    margin: 0;
    font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //background-color: #303030;
    //color: #ffffff;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding: 0 2rem;
    //background: #2c2d30;
    //background: #eee;
}

nav > h1 {
    padding-left: 2rem;
}

nav > div {
    display: flex;
}

.wallet-buttons {
    margin: 1rem;
}
.wallet-buttons button {
    margin: 0;
}

@media (max-width: 576px) {
    nav > div {
        flex-direction: column;
        align-items: center;
    }
    nav {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }
}
