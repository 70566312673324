.content {
	padding: 0.5em;
	max-width: 1080px;
	margin: auto;
}


.loader {
	display: inline-block;
	width: 32px;
	height: 32px;

	margin-top: 1em;
	margin-left: calc(50% - 16px);
}
.loader:after {
	content: " ";
	display: block;
	width: 32px;
	height: 32px;
	margin: 0;
	border-radius: 50%;
	border: 6px solid #eee;
	border-color: #eee transparent #eee transparent;
	animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inlineLoader {
	display: inline-block;
	width: 25px;
	height: 25px;
	margin-bottom: -7px;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}
.inlineLoader:after {
	content: " ";
	display: block;
	width: 25px;
	height: 25px;
	box-sizing: border-box;
	margin: 0;
	border-radius: 50%;
	border: 5px solid #ddd;
	border-color: #ddd transparent #ddd transparent;
	animation: loader 1.2s linear infinite;
}
