.navigation {
	background: #f4f4f4;
}

.logo {
	margin-top: 0.0em;
	margin-bottom: 0.0em;
	font-size: 2em;
}

.menu {
	z-index: 100;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-end;

	font-size: 1.1em;
	width: 100%;
	background-color: #f4f4f4;

	top: 64px;
	//position: absolute;
	max-height: 0;
	overflow: hidden;

	transition: max-height 0.2s ease 0.0s;
}

.menu.opened {
	max-height: 300px;
}

.more,
.entry {
	line-height: 64px;
	padding: 0 1.0em;
	background-color: #f4f4f4;

	transition: background-color 0.2s ease 0.0s;
	transition: color 1.0s ease 0.0s;
}

.more:hover,
.entry:hover {
	background-color: #eeeeee;
	text-decoration: none;
}

.entry {
	width: 100%;
}

.more {
	height: 64px;
	margin: 0;
	border: none;
	border-radius: 0;
}

.entry.active {
	color: #444444;
	background-color: #eeeeee;
}

@media (min-width: 576px) {
	.menu {
		position: absolute;
		max-height: 0;
	}
}

@media (min-width: 800px) {
	.menu {
		flex-flow: row wrap;
		width: inherit;
		position: inherit;
		max-height: inherit;
	}
	.entry {
		width: inherit;
	}
	.more {
		display: none;
	}
}
